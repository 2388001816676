import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminListService from "../services/admin-list.service";
import { Helmet } from "react-helmet";
import Footer from "./Footer2";

export default function AddGersByLink() {
  const { gers } = useParams();

  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const addGers = async () => {
    try {
      setMessage("");

      const responce = await AdminListService.linkToAddGers(gers);

      setMessage(responce?.data?.message);
    } catch (err) {
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        setMessage(err?.response?.data?.message);
      } else {
        setMessage("Some thing went wrong!");
      }
    }
  };
  useEffect(() => {
    console.log(gers);

    if (gers) {
      addGers();
    }
  }, [gers]);
  return gers ? (
    <div className="fix-white" style={{ display: "block" }}>
      <Helmet>
        <title>Add Gers</title>
      </Helmet>
      {message === "" ? (
        <div className="loader in">
          <div className="spinner-border main-spin"></div>
        </div>
      ) : (
        <>
          <div className="alert alert-secondary w-75 my-4 mx-auto" role="alert">
            <h5
              className="m-0"
              dangerouslySetInnerHTML={{ __html: message }}
              style={{ whiteSpace: "pre-line" }}
            />
          </div>
          <button
            class="btn btn-primary d-block m-auto"
            onClick={() => {
              navigate("/gers/add");
            }}
          >
            Go Back
          </button>
        </>
      )}
    </div>
  ) : (
    <>
      <Helmet>
        <title>Add Gers</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box">
          <ul className="points mb-0">
            <div className="form-outer">
              <div className="row">
                <div className="col-md-12" id="text">
                  <h1 className="text-center">How to Add GERS</h1>
                  <p className="register-p" style={{ wordBreak: "break-word" }}>
                    To add new GERS, simply modify the URL by entering the
                    initials of the new GERS, separated by commas. <br />
                    <strong>
                      For example: https://raymourresttest.com/gers/add/ABC,XYZ
                    </strong>
                  </p>
                  <ul className="gers-info-add">
                    <li>Use commas to separate multiple GERS.</li>
                    <li>Do not use spaces between names.</li>
                  </ul>
                  <div class="alert alert-info text-center">
                    After updating the URL, press Enter to submit.
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <Footer />
    </>
  );
}
