import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import { useSelector } from "react-redux";

export default function ManageLockup() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const selectKey = useRef(null);
  const selectVal = useRef(null);

  const pypLockUp = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    try {
      const responce = await AdminListService.lockupTable(page, limit);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            JOB_NUMBER: value.JOB_NUMBER,
            CERT_NUMBER: value.CERT_NUMBER,
            CERT_NUMBER_1: value.CERT_NUMBER_1,
            REDEMPTION_BEINS: value.REDEMPTION_BEINS,
            REDEMPTION_ENDS: value.REDEMPTION_ENDS,
            CERT_STATUS: value.CERT_STATUS,
            FIRST_NAME: value.FIRST_NAME,
            LAST_NAME: value.LAST_NAME,
            ADDRESS: value.ADDRESS,
            CITY: value.CITY,
            STATE: value.STATE,
            ZIP: value.ZIP,
            PHONE_NUMBER: value.PHONE_NUMBER,
            EmailAddress: value.EmailAddress,
            DENOMINATION: value.DENOMINATION,
            RECEIVED_METHOD: value.RECEIVED_METHOD,
            MAILED_DATE: value.MAILED_DATE,
            Redeemed_On_Date: value.Redeemed_On_Date,
            Mailed: value.Mailed,
            status_date: value.status_date,
            Agent: value.Agent,
            AgentDateTime: value.AgentDateTime,
            UNIQUE_ID: value.UNIQUE_ID,
            CARD_ORDERED: value.CARD_ORDERED,
            FILE_NOTES: value.FILE_NOTES,
            CODE: value.CODE,
            Campaign: value.Campaign,
            CampaignIDNo: value.CampaignIDNo,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      pypLockUp("1", limit);
    }
  }, [adminAccessToken]);

  const searchCertificates = async (page = 1, limit = "20") => {
    const key = await selectKey.current.value;
    const keyVal = await selectVal.current.value;
    if (keyVal !== "") {
      try {
        setLoading(true);
        const responce = await AdminListService.searchLockupTable(
          key,
          keyVal,
          page,
          limit
        );
        let res;
        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              id: value.id,
              JOB_NUMBER: value.JOB_NUMBER,
              CERT_NUMBER: value.CERT_NUMBER,
              CERT_NUMBER_1: value.CERT_NUMBER_1,
              REDEMPTION_BEINS: value.REDEMPTION_BEINS,
              REDEMPTION_ENDS: value.REDEMPTION_ENDS,
              CERT_STATUS: value.CERT_STATUS,
              FIRST_NAME: value.FIRST_NAME,
              LAST_NAME: value.LAST_NAME,
              ADDRESS: value.ADDRESS,
              CITY: value.CITY,
              STATE: value.STATE,
              ZIP: value.ZIP,
              PHONE_NUMBER: value.PHONE_NUMBER,
              EmailAddress: value.EmailAddress,
              DENOMINATION: value.DENOMINATION,
              RECEIVED_METHOD: value.RECEIVED_METHOD,
              MAILED_DATE: value.MAILED_DATE,
              Redeemed_On_Date: value.Redeemed_On_Date,
              Mailed: value.Mailed,
              status_date: value.status_date,
              Agent: value.Agent,
              AgentDateTime: value.AgentDateTime,
              UNIQUE_ID: value.UNIQUE_ID,
              CARD_ORDERED: value.CARD_ORDERED,
              FILE_NOTES: value.FILE_NOTES,
              CODE: value.CODE,
              Campaign: value.Campaign,
              CampaignIDNo: value.CampaignIDNo,
            });
          });
          settableData([...results]);

          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        settableData([]);
        setLimit("20");
        setTotalResults("0");
        setTotalPages("0");
        setCurrentPage(1);
        setLoading(false);
      }
    } else {
      pypLockUp("1", limit);
    }
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100  align-middle">
          <thead>
            <tr>
              <th>JOB_NUMBER</th>
              <th>CERTI INFO</th>
              <th>USER INFO </th>
              <th>ALL DATE</th>
              <th>Agent</th>
              <th>OTHER INFO</th>
              <th>Edit INFO</th>
            </tr>
          </thead>
          <tbody>
            {slice.length ? (
              slice.map((el, index) => (
                <tr key={index}>
                  <td>{el.JOB_NUMBER}</td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>CERT_NUMBER : </strong>
                      {el.CERT_NUMBER}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CERT_NUMBER_1 : </strong>
                      {el.CERT_NUMBER_1}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>REDEMPTION_BEINS : </strong>
                      {el.REDEMPTION_BEINS}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>REDEMPTION_ENDS : </strong>
                      {el.REDEMPTION_ENDS}
                    </div>
                    <div className="mt-1 mb-1">
                      <strong>CERT_STATUS : </strong>
                      {el.CERT_STATUS}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>FIRST_NAME : </strong>
                      {el.FIRST_NAME}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>LAST_NAME : </strong>
                      {el.LAST_NAME}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>ADDRESS : </strong>
                      {el.ADDRESS}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CITY : </strong>
                      {el.CITY}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>STATE : </strong>
                      {el.STATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>ZIP : </strong>
                      {el.ZIP}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>PHONE_NUMBER : </strong>
                      {el.PHONE_NUMBER}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>STATE : </strong>
                      {el.STATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>EmailAddress : </strong>
                      {el.EmailAddress}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>DENOMINATION : </strong> {el.DENOMINATION}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>RECEIVED_METHOD : </strong> {el.RECEIVED_METHOD}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>RECIEVED_DATE : </strong>
                      {el.Redeemed_On_Date}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>MAILED_DATE : </strong>
                      {el.MAILED_DATE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>status_date : </strong>
                      {el.status_date}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Mailed : </strong>
                      {el.Mailed}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>Agent : </strong>
                      {el.Agent}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Agent Date Time : </strong>
                      {el.AgentDateTime}
                    </div>
                  </td>

                  <td>
                    <div className="mt-1 mb-1">
                      <strong>UNIQUE_ID : </strong> {el.UNIQUE_ID}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CARD_ORDERED : </strong> {el.CARD_ORDERED}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>FILE_NOTES : </strong> {el.FILE_NOTES}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CODE : </strong> {el.CODE}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>CampaignIDNo : </strong> {el.CampaignIDNo}
                    </div>

                    <div className="mt-1 mb-1">
                      <strong>Campaign : </strong> {el.Campaign}
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        to={"/lookup-edit/" + el.id}
                        className="btn btn-sm btn-primary"
                        title=""
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={parseInt(currentPage)}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              if (selectVal?.current?.value !== "") {
                searchCertificates(e, limit);
              } else {
                pypLockUp(e, limit);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Manage PYP Lookup ({totalResults})
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-primary d-inline-block float-end btn-sm"
                >
                  Back
                </button>
              </h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <select
                    className="form-select mt-2 mb-2"
                    onChange={(e) => {
                      if (selectVal?.current?.value !== "") {
                        searchCertificates(1, e.target.value);
                      } else {
                        pypLockUp(1, e.target.value);
                      }
                    }}
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>

                    <option value={totalResults}>All</option>
                  </select>
                </div>
                <div className="col-md-12 col-lg-6 ms-auto">
                  <div className="input-group mt-2 mb-2">
                    <select className="form-select" ref={selectKey}>
                      <option value="all">All</option>
                      <option value="JOB_NUMBER">Job Number</option>
                      <option value="CERT_NUMBER">Cert Number</option>
                      <option value="CERT_NUMBER_1">Cert Number 1</option>
                      <option value="REDEMPTION_BEINS">Redemption Begin</option>
                      <option value="REDEMPTION_ENDS">Redemption End</option>
                      <option value="CERT_STATUS">Certificate Status</option>
                      <option value="FIRST_NAME">First Name</option>
                      <option value="LAST_NAME">Last Name</option>
                      <option value="ADDRESS">Address</option>
                      <option value="CITY">City</option>
                      <option value="STATE">State</option>
                      <option value="ZIP">Zip</option>
                      <option value="PHONE_NUMBER">Phone Number</option>
                      <option value="EmailAddress">Email</option>
                      <option value="DENOMINATION">Denomination</option>
                      <option value="RECEIVED_METHOD">Recieved Method</option>
                      <option value="Redeemed_On_Date">Recieved Date</option>
                      <option value="MAILED_DATE">Mailed Date</option>
                      <option value="status_date">Status Date</option>
                      <option value="Mailed">Mailed</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      ref={selectVal}
                      onKeyUp={(e) => {
                        if (e?.keyCode === 13) {
                          searchCertificates();
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={() => {
                        searchCertificates("1", limit);
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-primary"
                      type="reset"
                      onClick={() => {
                        selectVal.current.value = "";
                        selectKey.current.value = "all";
                        pypLockUp("1", limit);
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <Table data={tableData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
